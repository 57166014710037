html, body {
  //margin: 0;
  //padding: 0;
  //overflow-x: hidden;
}

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/bootstrap/main.css';
@import '~@fullcalendar/list/main.css';

$primary-color: #3f51b5;
$secondary-color: #f50057;

.btn, [type="button"] {
  &:focus, &:active {
    outline: none;
  }
}

.blocked-dates-calendar {
  .fc-day, .fc-highlight, .fc-day-top {
    cursor: pointer;
  }

}

.gcal-event, .blocked-date-event {
  cursor: auto !important;

  &:hover, &:visited, &:link {
  }

  .fc-content {
    padding-left: 2px;
  }
}

.material-ui-button-override {
  background: #3f51b5;
  border: none;
}

.lesson-event, .fc-event {
  cursor: pointer;
  @extend .material-ui-button-override;

  &.max-occupied {
    opacity: 0.6;
    cursor: auto;
  }
}

.user-lesson-event {
  .fc-list-item-title {
    a {
      cursor: pointer;
      text-decoration: underline;
      color: $primary-color;
      &:hover {
        text-decoration: underline;
        color: $primary-color;
      }
    }
  }
}


.btn.btn-primary {
  background-color: white;
  color: black;
  border: 1px solid $primary-color;

  &:hover {
    background-color: $primary-color;
    color: white;
  }
}

.btn.btn-primary.active {
  background: $primary-color !important;
  border: 1px solid $primary-color;

  &:hover {
    background-color: transparent;
  }
}

.lesson-event.lesson-now {
  border: 3px dotted #f50057 !important;
}

$today-color: #d1ecf1;
.fc-today-button[disabled] {
  background-color: $today-color;
  border: 1px solid $today-color;
  color: black;

  &:hover {
    background-color: $today-color;
    color: black;
  }
}
.fc-left {
  button {
    margin-top: 10px;
  }
}

a {
  &.active {
    text-decoration: underline;
  }
  &.MuiButton-containedPrimary:hover{
    color: white;
  }
  &:hover {
    color: inherit;
  }
}
a.active {
  span.MuiButton-label .MuiButton-startIcon {
    display: inline-block;
    margin-top: 7px;
  }
}
